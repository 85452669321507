// ./containers/HomePage/HomePage.js
import React from 'react';
import PropTypes from 'prop-types';
import { basePageWrap } from '../BasePage';
import PageContent from '../../components/PageContent';
import Hero from '../../components/Hero';
import s from './HomePage.module.css';

const HomePage = ({ title, body }) => {
    return (
        <PageContent title={title} body={body}>
            {/* Pass Hero as a child to PageContent */}
            <Hero title={title} />
        </PageContent>
    );
};

HomePage.defaultProps = {
    title: '',
    body: [],
};

HomePage.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.array,
};

export default basePageWrap(HomePage);
