import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

const BaseImage = ({ image, isThumbnail = false, className }) => {
    const { fullUrl, alt, srcset, srcset_webp } = isThumbnail ? image.thumbnail : image.original;

    // Safely handle srcset, making sure it's defined
    const srcSetString = srcset ? `
        ${srcset.small?.src} 480w,
        ${srcset.medium?.src} 768w,
        ${srcset.large?.src} 1024w,
        ${srcset.extra_large?.src} 1200w
    `.trim() : '';

    const srcSetWebpString = srcset_webp ? `
        ${srcset_webp.small?.src} 480w,
        ${srcset_webp.medium?.src} 768w,
        ${srcset_webp.large?.src} 1024w,
        ${srcset_webp.extra_large?.src} 1200w
    `.trim() : '';

    return (
        <div className={className}>
            <Image
                src={fullUrl}  // Use the full URL provided by the backend
                alt={alt}
                width={1200}  // Default width for the responsive image
                height={800}  // Default height for the responsive image
                sizes="(max-width: 480px) 480px,
                       (max-width: 768px) 768px,
                       (max-width: 1024px) 1024px,
                       1200px"  // Define sizes for different screen widths
                srcSet={srcSetString || undefined}  // Use srcSet only if it's defined
                priority={true}  // Ensure it loads with high priority (optional)
                fill={false}     // Use fill={false} since 'layout' is no longer supported
                quality={75}     // Adjust image quality (optional)
            />
        </div>
    );
};

BaseImage.propTypes = {
    image: PropTypes.shape({
        fullUrl: PropTypes.string.isRequired,  // Full URL is now required
        original: PropTypes.shape({
            alt: PropTypes.string.isRequired,
            srcset: PropTypes.shape({
                small: PropTypes.shape({
                    src: PropTypes.string,
                }),
                medium: PropTypes.shape({
                    src: PropTypes.string,
                }),
                large: PropTypes.shape({
                    src: PropTypes.string,
                }),
                extra_large: PropTypes.shape({
                    src: PropTypes.string,
                }),
            }),
        }).isRequired,
        thumbnail: PropTypes.shape({
            alt: PropTypes.string.isRequired,
            srcset: PropTypes.shape({
                small: PropTypes.shape({
                    src: PropTypes.string,
                }),
                medium: PropTypes.shape({
                    src: PropTypes.string,
                }),
                large: PropTypes.shape({
                    src: PropTypes.string,
                }),
                extra_large: PropTypes.shape({
                    src: PropTypes.string,
                }),
            }),
        }),
    }).isRequired,
    isThumbnail: PropTypes.bool,
    className: PropTypes.string,
};

export default BaseImage;
