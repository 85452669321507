import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../PageTitle/PageTitle';
import PageIntro from '../PageIntro/PageIntro';
import s from './PageHeader.module.css';

const PageHeader = ({ title, intro }) => {
    return (
        <div className={s.HeaderContainer}>
            <PageTitle title={title} />
            <PageIntro intro={intro} />
        </div>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.string,
};

PageHeader.defaultProps = {
    title: '',
    intro: '',
};

export default PageHeader;
