import React from 'react';
import PropTypes from 'prop-types';
import s from './PageTitle.module.css';

const PageTitle = ({ title }) => {
    return <h1 className="tracking-tight inline font-semibold text-[2.5rem] lg:text-5xl">{title}</h1>;
};

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

PageTitle.defaultProps = {
    title: '',
};

export default PageTitle;
