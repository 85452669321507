// components/PageContent.js
import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../PageHeader';
import BaseImage from '../BaseImage';
import s from './PageContent.module.css';

const PageContent = ({ title, intro, authorImage, body, children }) => {
    return (
        <div className={`${s.ContentWrapper} container mx-auto px-4 sm:px-8 lg:px-16 xl:px-20`}>
            {/* Apply the container to all elements inside PageContent */}
            <div className={`${s.PageHeader} section`}>
                <PageHeader title={title} intro={intro} />
            </div>

            {authorImage && (
                <div className={`datablock`}>
                    <BaseImage
                        img={{
                            original: {
                                src: authorImage.url,
                                alt: authorImage.alt,
                                width: authorImage.width,
                                height: authorImage.height,
                            },
                        }}
                    />
                </div>
            )}

            {/* Render additional children (e.g., from the page) */}
            <div className={`datablock`}>
                {children}
            </div>

            {/* Render body content */}
            <div className={s.BodyContent}>
                {body.map((content, index) => (
                    <div key={index} dangerouslySetInnerHTML={{ __html: content.richtext }} className="subheader" />
                ))}
            </div>
        </div>
    );
};

PageContent.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.string,
    authorImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    body: PropTypes.array,
    children: PropTypes.node,
};

PageContent.defaultProps = {
    title: '',
    intro: '',
    authorImage: null,
    body: [],
};

export default PageContent;
