import PropTypes from 'prop-types';
import s from './PageIntro.module.css';
import RawHtml from '../RawHtml';

const PageIntro = ({ intro }) => {
    return (
        <div className="w-full md:w-1/2 my-2 text-lg lg:text-xl font-normal text-neutralDark block max-w-full text-center md:text-left">
            <RawHtml html={intro} />
        </div>
    );
};

PageIntro.propTypes = {
    intro: PropTypes.string, // Define intro prop type
};

PageIntro.defaultProps = {
    intro: '',
};

export default PageIntro;
